import React from 'react';

import dummyAvatar from '@common/react/images/avatar.jpg';
import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import { Doctor } from '@commonTuna/react/objects/BaseDoctor';

interface Props {
	doctor: Doctor;
}

const ProviderInfo: React.FC<Props> = ({
	doctor,
}) => {
	const {
		nameEn,
		descriptionEn,
		originalAvatar,
		averageReviewRate,
		totalReviewCount,
	} = doctor;
	return (
		<div className="provider-info clearfix">
			<div className="pull-left avatar-container">
				<ImageLazy
					className="avatar"
					height="100%"
					width="100%"
					alt="avatar"
					title={nameEn}
					src={originalAvatar ? `/remote/${originalAvatar}` : dummyAvatar}
				/>
			</div>
			<div>
				<h2>
					{nameEn}
					{typeof averageReviewRate !== 'undefined' && <div className="avg-rate">
						<span title="rate" className="stars ml10">
							{Array.from({ length: Math.round(+averageReviewRate || 0) })
								.map((_, i) => <i className="fa fa-star" key={i} aria-hidden="true" />)}
						</span>
						<span title="review count" className="review-count">
							(
							{+(totalReviewCount ?? 0)}
							)
						</span>
					</div>}
				</h2>
				<div dangerouslySetInnerHTML={{ __html: descriptionEn }} />
			</div>
		</div>
	);
};

export default ProviderInfo;
